<script>
export default {
  name: "AppFooter",
  computed: {
    getFullYear() {
      return new Date().getFullYear();
    },
  },
  i18n: {
    messages: {
      en: {
        footerText:
          "iOX Broker is a UK registered company offering its services worldwide, strictly observing the laws of the country in which the client is located. Investing with leveraged funds involves a high level of risk and may not be suitable for all investors. The information provided on this website is not intended for distribution or use in any jurisdiction where such distribution or use would be contrary to local laws or regulations. Access to this website is at the sole discretion of the user. The content of the website does not constitute an offer to enter into transactions.",
      },
      ru: {
        footerText:
          "iOX Broker это компания, зарегистрированная в Великобритании, которая предлагает свои услуги по всему миру, строго соблюдая законодательство страны, в которой находится клиент. Инвестирование с использованием кредитных средств сопряжено с высоким уровнем риска и может быть неприемлемо для всех инвесторов. Информация, представленная на этом сайте, не предназначена для распространения или использования в тех юрисдикциях, где такое распространение или использование противоречит местным законам или нормативным актам. Посещение этого сайта осуществляется исключительно по собственному усмотрению пользователя. Содержание сайта не является предложением о заключении сделок.",
      },
      fr: {
        footerText:
          "iOX Broker est une société enregistrée au Royaume-Uni, qui propose ses services dans le monde entier, en respectant strictement les lois du pays dans lequel se trouve le client. L’investissement à effet de levier implique un niveau de risque élevé et peut ne pas convenir à tous les investisseurs. Les informations fournies sur ce site ne sont pas destinées à être distribuées ou utilisées dans une juridiction où une telle distribution ou utilisation serait contraire aux lois ou réglementations locales. La visite de ce site est uniquement à la discrétion de l'utilisateur. Le contenu du site ne constitue pas une offre de conclusion de transactions.",
      },
      de: {
        footerText:
          "iOX Broker ist ein in Großbritannien registriertes Unternehmen, das seine Dienstleistungen weltweit anbietet und dabei die Gesetze des Landes, in dem der Kunde ansässig ist, strikt beachtet. Leveraged Investing birgt ein hohes Risiko und ist möglicherweise nicht für alle Anleger geeignet. Die auf dieser Website bereitgestellten Informationen sind nicht für die Verbreitung oder Nutzung in einer Gerichtsbarkeit bestimmt, in der eine solche Verbreitung oder Nutzung gegen lokale Gesetze oder Vorschriften verstoßen würde. Der Besuch dieser Website liegt ausschließlich im eigenen Ermessen des Nutzers. Der Inhalt der Website stellt kein Angebot zum Abschluss von Geschäften dar.",
      },
    },
  },
};
</script>

<template>
  <footer class="footer">
    <div class="container">
      <div class="row row-gap-30">
        <div class="col-md-4 col-xl-2">
          <div class="footer__logo">
            <router-link :to="$localePath({ name: 'Home' })">
              <img src="@/assets/img/logo.svg" alt="" />
            </router-link>
          </div>
          <div class="footer__soc">
            <div class="soc__item">
              <span class="ic-mail soc-item-icon"></span>
              <a href="mailto:support@i-ox.com">Email</a>
            </div>
            <div class="soc__item">
              <span class="ic-send soc-item-icon"></span>
              <a href="tg://resolve?domain=iox_support_bot">Telegram</a>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-xl-4">
          <div class="row row-gap-30">
            <div class="col-6">
              <router-link
                :to="$localePath({ name: 'Markets' })"
                class="footer__title"
              >
                {{ $t("menu.markets") }}
              </router-link>
              <ul class="footer__menu">
                <li>
                  <router-link
                    :to="$localePath({ name: 'Currency' })"
                    class="footer__menu-item"
                  >
                    {{ $t("subMenu.currency") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Indices' })"
                    class="footer__menu-item"
                  >
                    {{ $t("subMenu.indices") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Stock' })"
                    class="footer__menu-item"
                  >
                    {{ $t("subMenu.stock") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Forex' })"
                    class="footer__menu-item"
                  >
                    {{ $t("subMenu.forex") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Commodities' })"
                    class="footer__menu-item"
                  >
                    {{ $t("subMenu.commodities") }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="col-6">
              <div class="footer__title">{{ $t("company") }}</div>
              <ul class="footer__menu mb-30">
                <li>
                  <router-link
                    :to="$localePath({ name: 'About' })"
                    class="footer__menu-item"
                  >
                    {{ $t("menu.about") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Safety' })"
                    class="footer__menu-item"
                  >
                    {{ $t("menu.safe") }}
                  </router-link>
                </li>
              </ul>
              <div>
                <router-link
                  :to="$localePath({ name: 'Platform' })"
                  class="footer__title"
                >
                  {{ $t("menu.platform") }}
                </router-link>
              </div>
              <router-link
                :to="$localePath({ name: 'Contacts' })"
                class="footer__title"
              >
                {{ $t("menu.contacts") }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="footer__text">
            <p v-html="$t('footerText')"></p>
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <div class="row row-gap-20 flex-md-row-reverse">
          <div class="col-md-6 text-md-end">
            <div class="bottom__links">
              <router-link :to="$localePath({ name: 'Privacy' })">
                Privacy Policy
              </router-link>
            </div>
          </div>
          <div class="col-md-6">
            <p>iOX Broker© {{ getFullYear }}, all rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer {
  border-top: 2px solid $blue;
  padding-top: 60px;
}
.footer__logo {
  margin-top: -10px;
  margin-bottom: 20px;
}
.footer__soc {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}
.soc__item {
  display: flex;
  gap: 10px;
  align-items: center;

  a {
    font-weight: 500;
    font-size: 14px;
    color: $white;
  }
}
.soc-item-icon {
  color: $blue;
  font-size: 18px;
}
.footer__title {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: $blue;
  margin-bottom: 10px;
}
.footer__menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer__menu-item {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: $white;
}
.footer__bottom {
  padding: 50px 0;
  font-size: 13px;
  line-height: 24px;
  font-weight: 600;
}
.bottom__links {
  display: inline-flex;
  align-items: center;
  gap: 50px;

  a {
    color: $white;
  }
}
.footer__text {
  font-weight: 300;
  line-height: 20px;
}

@media (hover: hover) {
  .bottom__links a:hover,
  .soc__item a:hover,
  .footer__menu-item:hover {
    color: $blue;
  }
  a.footer__title:hover {
    color: $white;
  }
}

@include media-breakpoint-down(md) {
  .footer {
    padding-top: 40px;
  }
  .footer__logo {
    margin-top: 0;
  }

  .footer__bottom {
    padding: 35px 0;
  }
}
</style>
